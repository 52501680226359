@import '../../../../styles/sizes';

.donate {
  p {
    max-width: 1000px;
  }
  .formContainer {
    background: var(--donate-form-background-color);
    margin-top: 50px;
    form {
      max-width: 860px;
      .block {
        border-top: 1px solid #afafaf;
        padding: 50px 0;
        &:first-child {
          border: none;
        }
        h3 {
          margin-bottom: 40px;
        }
        .row {
          display: flex;
          margin-bottom: 46px;
          &:last-child {
            margin-bottom: 0;
          }
          .input {
            margin-right: 40px;
            flex-grow: 1;
            position: relative;
            flex-basis: 30%;
            flex-shrink: 0;
            display: block;
            &:last-child {
              margin-right: 0;
            }
            textarea {
              margin-top: 40px;
            }
            input.error {
              box-shadow: 0px 0px 0px 1px var(--error-red);
              border-color: var(--error-red);
            }
          }
        }
        ul.list {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            margin-bottom: 40px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      button {
        margin-top: 16px;
        margin-bottom: 50px;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .formError {
    font-size: 30px;
    color: var(--error-red);
    background: var(--donate-form-background-color);
    padding-bottom: 50px;
  }
}

@media (max-width: $size-small) {
  .donate {
    p {
      max-width: 1000px;
    }
    .formContainer {
      form {
        max-width: 860px;
        margin-top: 30px;
        .block {
          padding: 30px 0 10px 0;
          h3 {
            margin-bottom: 30px;
          }
          .row {
            display: block;
            margin-bottom: 0;
            &:last-child {
              margin-bottom: 0;
            }
            .input {
              margin-right: 0;
              margin-bottom: 20px;
              input,
              textarea {
                padding: 12px 16px;
              }
              textarea {
                margin-top: 30px;
                margin-bottom: 0;
              }
            }
          }
          ul.list {
            li {
              margin-bottom: 30px;
            }
          }
          ul.controls {
            margin-bottom: 20px;
          }
        }
        button {
          margin-top: 0;
          margin-bottom: 30px;
        }
      }
    }
    .formError {
      font-size: 16px;
      padding-bottom: 30px;
    }
  }
}
