@import '../../../../styles/sizes';

.cause {
  p {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: $size-small) {
  .cause {
    p {
      margin-bottom: 24px;
    }
  }
}
