@import '../../../../styles/sizes';

.need {
  ul.list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      font-size: 30px;
      font-weight: 500;
      line-height: 2;
    }
  }
}

@media (max-width: $size-small) {
  .need {
    ul.list {
      li {
        font-size: 16px;
      }
    }
  }
}
