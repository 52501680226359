@import '../../styles/sizes';

nav {
  ul.menu {
    list-style: none;
    padding: 0;
    padding: 25px 0;
    margin: 0;
    text-align: right;
    li {
      display: inline-block;
      font-weight: 700;
      font-size: 20px;
    }
  }
}

@media (max-width: $size-small) {
  nav {
    ul.menu {
      text-align: center;
      padding: 130px 0 0 0;
      li {
        font-size: 10px;
      }
    }
  }
}
