@import '../../../../styles/sizes';

.hero {
  background-color: var(--theme-blue);
  background-image: url(../../../../assets/hero.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin-bottom: 60px;
  color: #fff;
  .content {
    height: calc(100vh - 75px);
    display: flex;
    align-items: center;
    h1 {
      max-width: 860px;
    }
    p {
      max-width: 860px;
      margin: 40px 0 60px 0;
    }
    .actions {
      display: flex;
      .button {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: $size-medium) {
  .hero {
    background-position: top;
    .content {
      height: auto;
      padding-bottom: 60px;
      h1 {
        text-align: center;
        max-width: 95%;
        margin: 20px auto 0 auto;
        line-height: 1.1;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: -30px;
          left: 0;
          right: 0;
          margin: auto;
          width: 60px;
          height: 3px;
          background: #fff;
        }
      }
      p {
        text-align: center;
        max-width: 100%;
        margin: 60px 0 105px 0;
        font-size: 23px;
        line-height: 1.13;
      }
    }
  }
}

@media (max-width: $size-small) {
  .hero {
    .content {
      .actions {
        text-align: center;
        display: block;
        .button {
          margin-right: 0;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
