@import '../../../../styles/sizes';

.footer {
  background: var(--theme-blue);
  color: #fff;
  padding: 120px 0 90px 0;
  .content {
    display: flex;
    justify-content: space-between;
  }
  .linkGroups {
    display: flex;
    div {
      margin-right: 100px;
      &:last-child {
        margin-right: 0;
      }
      ul {
        list-style: none;
        margin: 40px 0 0 0;
        padding: 0;
        li {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          font-size: 20px;
          a {
            color: rgba(255, 255, 255, 0.7);
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media (max-width: $size-medium) {
  .footer {
    .content {
      display: block;
      h4 {
        margin-bottom: 80px;
      }
      .linkGroups h4 {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
  }
}

@media (max-width: $size-small) {
  .footer {
    padding: 80px 0;
    .content {
      display: block;
      h4 {
        margin-bottom: 120px;
      }
      .linkGroups {
        display: block;
        div {
          margin-right: 0;
          margin-top: 100px;
          h4 {
            font-size: 16px;
            margin-bottom: 0;
          }
          ul {
            margin-top: 30px;
            li {
              font-size: 16px;
              margin-bottom: 24px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
