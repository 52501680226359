.terms {
  margin: 50px auto;
  h2 {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  h3 {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  p,
  li {
    font-size: 16px;
  }
}
